import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import media from 'styled-media-query';

const Footer = () => {
  return (
    <footer css="margin-top:50px;border-top:1px solid #B1B1B1;">
      <FooterInner>
        <img src="/images/logo.png" alt="" />
        <address>〒242-0018 神奈川県大和市深見西2丁目1-25</address>
        <address>TEL 046-263-0681</address>
        <div
          css={`
            text-align: center;
            margin-top: 25px;
          `}
        >
          <small>Copyright © Yamato Ayase Dental Assosiation All Rights Reserved.</small>
        </div>
      </FooterInner>
    </footer>
  );
};

const FooterInner = styled.div`
  width: 90%;
  max-width: 1150px;
  margin: 30px auto;
`;

export default Footer;
