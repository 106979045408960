import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = React.memo(({ user }) => {
  const router = useRouter();

  return (
    <HeaderWrapper>
      <img src="/images/logo_admin.svg" />
    </HeaderWrapper>
  );
});

// 768px = 1000px-232px(サイドメニューのwidth)
const HeaderWrapper = styled.div`
  height: 141px;
  width: 100%;
  min-width: 768px;
  display: flex;
  align-items: center;
  padding: 0 5%;
`;

export default Header;
