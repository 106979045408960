import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Router from 'next/router';

// サイドメニュー
const Sidemenu = ({ className }) => {
  return (
    <SidemenuArea className={className}>
      <nav css="margin-bottom:20px;">
        <SidemenuUl>
          <SidemenuLi>
            <Link href="/about">
              <SidemenuNavA href="">
                <FontAwesomeIcon
                  icon={['fas', 'tooth']}
                  css="margin-right: 2.4rem;color:#009D80;"
                />
                理事会
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  css="position: absolute;top: 50%;right: 8%;transform: translateY(-50%);"
                />
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
          <SidemenuLi>
            <Link href="/about">
              <SidemenuNavA href="">
                <FontAwesomeIcon
                  icon={['fas', 'tooth']}
                  css="margin-right: 2.4rem;color:#009D80;"
                />
                常務会
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  css="position: absolute;top: 50%;right: 8%;transform: translateY(-50%);"
                />
              </SidemenuNavA>
            </Link>
          </SidemenuLi>
        </SidemenuUl>
      </nav>
      <Button1 onClick={() => Router.push('/')}>
        市民サイトへ
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          css="position: absolute;top: 50%;right: 8%;transform: translateY(-50%);"
        />
      </Button1>
      <Button1 onClick={() => Router.push('/')}>
        理事会サイトへ
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          css="position: absolute;top: 50%;right: 8%;transform: translateY(-50%);"
        />
      </Button1>
      <Button2 onClick={() => Router.push('/')}>
        旧会員サイトへ
        <FontAwesomeIcon
          icon={['fas', 'chevron-right']}
          css="position: absolute;top: 50%;right: 8%;transform: translateY(-50%);"
        />
      </Button2>
    </SidemenuArea>
  );
};
const SidemenuArea = styled.div`
  /* width: 280px; */
  width: 30%;
  min-width: 200px;
  margin: 60px 0;
  margin-left: 2%;
`;
const SidemenuUl = styled.ul`
  list-style: none;
`;
const SidemenuLi = styled.li`
  height: 85px;
  background: #f8f8f8;
  margin: 6px 0;
`;
const SidemenuNavA = styled.a`
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  color: #009d80;
  line-height: 85px;
  padding: 0 6%;
  text-decoration: none;
  position: relative;
  &:hover {
    cursor: pointer;
    background: #eeeded;
  }
`;
const Button1 = styled.button`
  /* width: 280px; */
  width: 100%;
  height: 85px;
  background: #67af4c;
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
  margin: 6px auto;
  padding: 0 8%;
  text-align: left;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const Button2 = styled.button`
  /* width: 280px; */
  width: 100%;
  height: 85px;
  background: #fff;
  color: #000;
  font-size: 1.6rem;
  font-weight: bold;
  border: 1px solid #000;
  margin: 6px auto;
  padding: 0 8%;
  text-align: left;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export default Sidemenu;
